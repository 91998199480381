import {
  Room,
  Stage,
  StageName,
  VersionIgnorantFunnelEvent,
  getElementBySelector,
  getElementsBySelector,
  isInteger,
  onFunnelEntry,
  tryGetElementBySelector,
} from "../shared";
import {
  getDateSearchData,
  getFunnelEntryData,
  getFunnelEvents,
} from "./shared";

const stage: StageName = "room_selection";

export const getRoomSelection = (
  recordEvent: (event: VersionIgnorantFunnelEvent) => void,
  stageNumber: number
): Stage => ({
  active: isRoomSelection,
  onLoad: () =>
    onFunnelEntry(() =>
      recordEvent({
        event: "start",
        stage,
        stageNumber,
        data: getFunnelEntryData(),
      })
    ),
  trackedEvents: [
    ...getFunnelEvents(
      (event) => recordEvent({ ...event, stage, stageNumber }),
      getRoomSelectionData
    ),
  ],
});

const getRoomSelectionData = (requireVisibility = false) => ({
  ...getDateSearchData(),
  rooms: getRooms(requireVisibility),
});

const isRoomSelection = () => {
  try {
    getRoomSelectionData(true);
  } catch {
    return false;
  }

  return !!tryGetElementBySelector("[data-testid=accommodations-list]");
};

const getRooms = (requireVisibility: boolean): Room[] =>
  getElementsBySelector("[data-testid^=shopping-cart-item-accommodation]", {
    requireVisibility,
  })
    .map((e) => getRoom(e, requireVisibility))
    .filter((r) => r.quantity > 0);

const getRoom = (room: Element, requireVisibility: boolean): Room => {
  const { rate, quantity } = getRateAndQuantityFromDescription(room);

  return {
    type:
      getElementBySelector<HTMLBodyElement>(".shopping-item-header", {
        parent: room,
        getFirstIfMultiple: true,
      }).textContent ?? "",
    rate,
    adults: Number(
      getElementBySelector("[data-testid*=adults-occupancy]", {
        parent: room,
        requireVisibility,
      }).textContent ?? ""
    ),
    children: Number(
      tryGetElementBySelector("[data-testid*=kids-occupancy]", {
        parent: room,
        requireVisibility,
      })?.textContent ?? 0
    ),
    quantity,
  };
};

const getRateAndQuantityFromDescription = (room: Element) => {
  const description =
    getElementBySelector<HTMLBodyElement>(".shopping-item-description", {
      parent: room,
    }).textContent ?? "";

  for (let i = 0; i < description.length; i++) {
    const char = description[i];

    if (isInteger(char)) {
      continue;
    }

    return {
      // Expect description to be of format 'quantityx rate' (e.g. '2x Standard Rate')
      // or 'rate' (e.g. 'Standard Rate')
      rate: i > 0 ? description.substring(i + 2) : description,
      quantity: i > 0 ? Number(description.slice(0, i)) : 1,
    };
  }

  throw Error(`Description ('${description}') is in unexpected format`);
};
