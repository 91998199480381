import {
  Stage,
  StageName,
  VersionIgnorantFunnelEvent,
  tryGetElementBySelector,
} from "../shared";
import { getFunnelEvents } from "./shared";

const stage: StageName = "addon_selection";

export const getAddonSelection = (
  recordEvent: (event: VersionIgnorantFunnelEvent) => void,
  stageNumber: number
): Stage => ({
  active: isAddonSelection,
  trackedEvents: [
    ...getFunnelEvents((event) =>
      recordEvent({ ...event, stage, stageNumber })
    ),
  ],
});

const isAddonSelection = () =>
  !!tryGetElementBySelector("[data-testid=addons-page]");
