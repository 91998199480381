interface GetElementsBySelectorOptions {
  parent?: Document | Element;
  requireVisibility?: boolean;
}

export const getElementsBySelector = <T extends HTMLElement>(
  selector: string,
  {
    parent = document,
    requireVisibility = false,
  }: GetElementsBySelectorOptions = {}
) => {
  const elements = [...parent.querySelectorAll<T>(selector)];

  return requireVisibility
    ? elements.filter((e) => e.checkVisibility())
    : elements;
};

interface GetElementBySelectorOptions extends GetElementsBySelectorOptions {
  getFirstIfMultiple?: boolean;
}

export const tryGetElementBySelector = <T extends HTMLElement>(
  selector: string,
  { getFirstIfMultiple = false, ...options }: GetElementBySelectorOptions = {}
) => {
  const elements = getElementsBySelector<T>(selector, options);

  if (elements.length == 0) return null;
  if (!getFirstIfMultiple && elements.length > 1) {
    throw Error(
      `Expected one element matching ${selector}, found ${elements.length}.`
    );
  }

  return elements[0];
};

export const getElementBySelector = <T extends HTMLElement>(
  selector: string,
  options: GetElementBySelectorOptions = {}
) => {
  const element = tryGetElementBySelector<T>(selector, options);

  if (!element) {
    throw Error(`Expected an element matching ${selector}.`);
  }

  return element;
};
