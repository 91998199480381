import { FunnelEvent, FunnelId } from "../types";
import { tryGetElementBySelector, MutationObserverWrapper } from "./shared";
import { getFunnelV2Data } from "./v2";
import { getFunnelV3Data } from "./v3";
import { getScriptQueryParameters, getWebsiteData } from "./website";

const getFunnelId = (): Promise<FunnelId> =>
  new Promise((resolve) => {
    const timeoutLength = 10000;
    const timeout = setTimeout(() => {
      observer.disconnect();
      throw Error(`Failed to identify funnel version in ${timeoutLength}ms`);
    }, timeoutLength);

    const observer = new MutationObserverWrapper(() => {
      const funnelId = tryGetFunnelId();
      if (funnelId == null) return;

      clearTimeout(timeout);
      resolve(funnelId);
      observer.disconnect();
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
      runImmediately: true,
    });
  });

const tryGetFunnelId = (): FunnelId | null => {
  if (tryGetElementBySelector(".cb-bookingengine")) return "v3";
  if (tryGetElementBySelector(".page-header .hotel_name")) return "v2";
  if (getScriptQueryParameters().has("mode", "website")) return "website";
  return null;
};

export const getFunnelData = async (
  recordEvent: (event: FunnelEvent) => void
) => {
  switch (await getFunnelId()) {
    case "v2":
      return getFunnelV2Data(recordEvent);

    case "v3":
      return getFunnelV3Data(recordEvent);

    case "website":
      return getWebsiteData(recordEvent);
  }
};
