import { toDateString } from ".";

export const tryGetQueryParameter = (parameterName: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(parameterName);
};

export const tryGetDateQueryParameter = (
  parameterName: string
): string | null => toDateString(tryGetQueryParameter(parameterName));
