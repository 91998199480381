import { tryGetElementBySelector } from "./elementHelpers";
import { MutationObserverWrapper } from "./mutationObserverWrapper";
import { DocumentEvent, ElementEvent, Stage, WindowEvent } from "./stage";

export class Funnel {
  constructor(stages: Stage[]) {
    this.stages = stages;

    this.stageTracker.observe(document, {
      childList: true,
      attributes: true,
      subtree: true,
      runImmediately: true,
    });
  }

  private stageTracker = new MutationObserverWrapper(() => {
    const activeStage = this.stages.find((s) => s.active());

    if (activeStage && activeStage != this.currentStage) {
      this.currentStage = activeStage;
      activeStage.onLoad?.();

      this.eventTrackingController.abort();
      this.trackedEvents.clear();
      this.eventTrackingController = new AbortController();
    }

    // Always try to track events, in case the
    // element we were waiting for was just added
    this.trackEvents();
  });

  private eventTrackingController = new AbortController();
  private trackedEvents = new Set<number>();

  private currentStage: Stage | undefined;
  private stages: Stage[];

  private trackEvents() {
    this.currentStage?.trackedEvents
      ?.map((e, i) => {
        // Don't retrack events that have already been added
        if (this.trackedEvents.has(i)) return false;

        switch (e.type) {
          case "window":
            return this.trackWindowEvent(e);

          case "document":
            return this.trackDocumentEvent(e);

          case "element":
            return this.trackElementEvent(e);
        }
      })
      .forEach((added, i) => added && this.trackedEvents.add(i));
  }

  private trackWindowEvent({ event, listener }: WindowEvent) {
    window.addEventListener(event, listener, {
      signal: this.eventTrackingController.signal,
    });

    return true;
  }

  private trackDocumentEvent({ event, listener }: DocumentEvent) {
    document.addEventListener(event, listener, {
      signal: this.eventTrackingController.signal,
    });

    return true;
  }

  private trackElementEvent({ event, selector, listener }: ElementEvent) {
    const element = tryGetElementBySelector(selector);
    if (!element) return false;

    if (event === "change" && element.tagName === "BUTTON") {
      const observer = new MutationObserver(listener);
      observer.observe(element, {
        subtree: true,
        childList: true,
        characterData: true,
      });

      this.eventTrackingController.signal.addEventListener("abort", () =>
        observer.disconnect()
      );
    } else {
      element.addEventListener(event, listener, {
        signal: this.eventTrackingController.signal,
      });
    }

    return true;
  }

  // Used by tests (observers persist between tests)
  public stopTracking() {
    this.stageTracker.disconnect();
    this.eventTrackingController.abort();
  }
}
