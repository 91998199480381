interface MutationObserverWrapperInit extends MutationObserverInit {
  runImmediately?: boolean;
}

export class MutationObserverWrapper extends MutationObserver {
  constructor(callback: () => void) {
    super(callback);

    this.callback = callback;
  }

  private callback: () => void;

  public observe(
    target: Node,
    { runImmediately, ...options }: MutationObserverWrapperInit = {}
  ): void {
    if (runImmediately) {
      this.callback();
    }

    super.observe(target, options);
  }
}
