import {
  EventName,
  Funnel,
  getReferrer,
  getSharedEventData,
  getVisibilityChangeEvents,
  onFunnelEntry,
} from "./shared";
import { FunnelEvent } from "../types";

export const getScriptQueryParameters = () => {
  const scripts = document.getElementsByTagName("script");
  const bookingScraperScript = [...scripts].find((s) =>
    s.src.includes("tracking.crm.cloudbeds")
  );

  if (!bookingScraperScript) {
    throw Error("Unable to find booking scraper source");
  }

  return new URL(bookingScraperScript.src).searchParams;
};

export const getWebsiteData = (recordEvent: (event: FunnelEvent) => void) => {
  const propertyId = getScriptQueryParameters().get("propertyId");

  if (!propertyId) {
    throw Error("Unable to find property id from script url");
  }

  const recordWebsiteEvent = (event: EventName, getData?: () => object) =>
    recordEvent({
      ...getSharedEventData({ getPropertyIdOverride: () => propertyId }),
      event,
      stage: window.location.pathname,
      stageNumber: null,
      data: getData?.() ?? null,
      funnelId: "website",
    });

  return new Funnel([
    {
      active: () => true,
      onLoad: () => {
        const getData = () => ({
          referrer: getReferrer(),
        });

        const isFunnelEntry = onFunnelEntry(() =>
          recordWebsiteEvent("start", getData)
        );

        if (!isFunnelEntry) {
          recordWebsiteEvent("change_stage", getData);
        }
      },
      trackedEvents: getVisibilityChangeEvents(recordWebsiteEvent),
    },
  ]);
};
