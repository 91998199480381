import {
  Funnel,
  getSharedEventData,
  VersionIgnorantFunnelEvent,
} from "../shared";
import { FunnelEvent } from "../../types";
import { getConfirmation } from "./confirmation";
import { getPayment } from "./payment";
import { getRoomSelection } from "./roomSelection";
import { getPropertyIdForSession } from "./shared/propertyId";

export const getFunnelV2Data = (recordEvent: (event: FunnelEvent) => void) => {
  const recordFunnelV2Event = (event: VersionIgnorantFunnelEvent) =>
    recordEvent({
      ...event,
      ...getSharedEventData(
        event.stage == "confirmation"
          ? {
              getPropertyIdOverride: getPropertyIdForSession,
            }
          : {}
      ),
      funnelId: "v2",
    });

  return new Funnel([
    getRoomSelection(recordFunnelV2Event, 1),
    getPayment(recordFunnelV2Event, 2),
    getConfirmation(recordFunnelV2Event, 3),
  ]);
};
