import {
  Stage,
  StageName,
  VersionIgnorantFunnelEvent,
  getElementBySelector,
  getPropertyId,
  tryGetElementBySelector,
} from "../shared";
import { getFunnelEvents } from "./shared";
import { setPropertyIdForSession } from "./shared/propertyId";

const stage: StageName = "payment";

export const getPayment = (
  recordEvent: (event: VersionIgnorantFunnelEvent) => void,
  stageNumber: number
): Stage => ({
  active: isPayment,
  trackedEvents: [
    ...getFunnelEvents(
      (event) =>
        recordEvent({
          ...event,
          stage,
          stageNumber,
        }),
      getPaymentData
    ),
    {
      type: "element",
      event: "click",
      selector: completeReservationButtonSelector,
      listener: () => {
        setPropertyIdForSession(getPropertyId());

        recordEvent({
          event: "click.continue",
          stage,
          stageNumber,
          data: getPaymentData(),
        });
      },
    },
  ],
});

const completeReservationButtonSelector = ".finalize";

const getPaymentData = (requireVisibility = false) => ({
  email: getElementBySelector<HTMLInputElement>("[name=email]", {
    requireVisibility,
  }).value,
});

const isPayment = () => {
  try {
    getPaymentData(true);
  } catch {
    return false;
  }

  const wrapper = tryGetElementBySelector(".checkout");
  return !!wrapper && !wrapper.classList.contains("hide");
};
