import { FunnelEvent } from "../types";

// https://github.com/cloudbeds/booking-tracking-kafka-producer/blob/main/src/Models/TrackedEvent.cs
interface TrackedEvent {
  event: string;
  stage: string;
  stageNumber: number | null;
  funnelId: string;
  userId: string;
  sessionId: string;
  userAgent: string;
  data: string;
}

const toTrackedEvent = (event: FunnelEvent): TrackedEvent => ({
  ...event,
  data: JSON.stringify(event.data),
});

export const postEvent = (event: FunnelEvent) =>
  fetch(`${process.env.DATA_WRITER_URL}/track`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(toTrackedEvent(event)),
  });
