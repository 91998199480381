import { ElementEvent } from "../../shared";

const continueButtonSelector = "[data-testid=shopping-cart-confirm-button]";
const summaryContinueButtonSelector =
  "[data-testid=shopping-cart-summary-confirm-button]";
const bannerContinueButtonSelector =
  "[data-testid=shopping-cart-banner-confirm-button]";

export const getShoppingCartTrackingEvents = (
  listener: () => void
): ElementEvent[] => [
  {
    type: "element",
    event: "click",
    selector: continueButtonSelector,
    listener,
  },
  {
    type: "element",
    event: "click",
    selector: summaryContinueButtonSelector,
    listener,
  },
  {
    type: "element",
    event: "click",
    selector: bannerContinueButtonSelector,
    listener,
  },
];
