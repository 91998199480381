import {
  Stage,
  StageName,
  VersionIgnorantFunnelEvent,
  getElementBySelector,
  tryGetElementBySelector,
} from "../shared";

const stage: StageName = "confirmation";

export const getConfirmation = (
  recordEvent: (event: VersionIgnorantFunnelEvent) => void,
  stageNumber: number
): Stage => ({
  active: isConfirmation,
  onLoad: () =>
    recordEvent({
      event: "finish",
      stage,
      stageNumber,
      data: getConfirmationData(),
    }),
});

const getConfirmationData = (requireVisibility = false) => ({
  reservationNumber:
    getElementBySelector<HTMLElement>(".reserve_number", {
      requireVisibility,
    }).textContent ?? "",
});

const isConfirmation = () => {
  try {
    getConfirmationData(true);
  } catch {
    return false;
  }

  const wrapper = tryGetElementBySelector(".reserve_success");
  return !!wrapper && !wrapper.classList.contains("hide");
};
