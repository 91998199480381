import { ElementEvent, WindowEvent } from "../../shared";
import { tryGetDateHashParameter } from "./urlHelpers";

export const datepickerSearchButtonSelector = ".datepicker_search_button";

export const getDateSearchData = () => ({
  startDate: tryGetDateHashParameter("checkin"),
  endDate: tryGetDateHashParameter("checkout"),
});

export const getDateSearchEvents = (
  listener: (data: object) => void
): (WindowEvent | ElementEvent)[] => [
  {
    type: "window",
    event: "hashchange",
    listener: () => listener(getDateSearchData()),
  },
  {
    type: "element",
    event: "click",
    selector: datepickerSearchButtonSelector,
    listener: () => listener(getDateSearchData()),
  },
];
