import { tryGetQueryParameter } from "./urlHelpers";

export const onFunnelEntry = (callback: () => void) => {
  const funnelEntryMarker = "funnel-entry-marker";
  const isFunnelEntry = !sessionStorage.getItem(funnelEntryMarker);

  if (isFunnelEntry) {
    callback();
  }

  sessionStorage.setItem(funnelEntryMarker, "true");
  return isFunnelEntry;
};

export const getReferrer = () => tryGetQueryParameter("utm_source");
