import {
  Stage,
  StageName,
  VersionIgnorantFunnelEvent,
  getElementsBySelector,
  tryGetElementBySelector,
} from "../shared";

const stage: StageName = "confirmation";

export const getConfirmation = (
  recordEvent: (event: VersionIgnorantFunnelEvent) => void,
  stageNumber: number
): Stage => ({
  active: isConfirmation,
  onLoad: () =>
    recordEvent({
      event: "finish",
      stage,
      stageNumber,
      data: getConfirmationData(),
    }),
});

const getConfirmationData = (requireVisibility = false) => ({
  reservationNumber: getReservationNumber(requireVisibility),
});

const isConfirmation = () => {
  try {
    getConfirmationData(true);
  } catch {
    return false;
  }

  return !!tryGetElementBySelector("[data-testid=confirmation-page]");
};

const getReservationNumber = (requireVisibility: boolean) => {
  const reservationNumber = getElementsBySelector<HTMLElement>("p", {
    requireVisibility,
  })
    .map((e) => e.textContent ?? "")
    .find((t) => t.includes("#"))
    ?.split("#")[1];

  if (!reservationNumber) {
    throw Error("Unable to find reservation number.");
  }

  return reservationNumber;
};
