import {
  Funnel,
  getSharedEventData,
  VersionIgnorantFunnelEvent,
} from "../shared";
import { FunnelEvent } from "../../types";
import { getRoomSelection } from "./roomSelection";
import { getDateSelection } from "./dateSelection";
import { getAddonSelection } from "./addonSelection";
import { getGuestDetails } from "./guestDetails";
import { getPayment } from "./payment";
import { getConfirmation } from "./confirmation";

export const getFunnelV3Data = (recordEvent: (event: FunnelEvent) => void) => {
  const recordFunnelV3Event = (event: VersionIgnorantFunnelEvent) =>
    recordEvent({
      ...event,
      ...getSharedEventData(),
      funnelId: "v3",
    });

  return new Funnel([
    getDateSelection(recordFunnelV3Event, 1),
    getRoomSelection(recordFunnelV3Event, 2),
    getAddonSelection(recordFunnelV3Event, 3),
    getGuestDetails(recordFunnelV3Event, 4),
    getPayment(recordFunnelV3Event, 5),
    getConfirmation(recordFunnelV3Event, 6),
  ]);
};
