import dayjs from "dayjs";

type toDateString = {
  (dateString: string): string;
  (dateString: string | null): string | null;
};

export const toDateString: toDateString = ((dateString) =>
  dateString == null
    ? null
    : dayjs(dateString).format("YYYY-MM-DD")) as toDateString;
