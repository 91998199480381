import {
  Room,
  Stage,
  StageName,
  VersionIgnorantFunnelEvent,
  getElementBySelector,
  getElementsBySelector,
  onFunnelEntry,
  tryGetElementBySelector,
} from "../shared";
import {
  getDateSearchData,
  getFunnelEntryData,
  getFunnelEvents,
} from "./shared";

const stage: StageName = "room_selection";

export const getRoomSelection = (
  recordEvent: (event: VersionIgnorantFunnelEvent) => void,
  stageNumber: number
): Stage => ({
  active: isRoomSelection,
  onLoad: () =>
    onFunnelEntry(() =>
      recordEvent({
        event: "start",
        stage,
        stageNumber,
        data: getFunnelEntryData(),
      })
    ),
  trackedEvents: [
    ...getFunnelEvents(
      (event) =>
        recordEvent({
          ...event,
          stage,
          stageNumber,
        }),
      getRoomSelectionData
    ),
    {
      type: "element",
      event: "click",
      selector: bookNowButtonSelector,
      listener: () =>
        recordEvent({
          event: "click.continue",
          stage,
          stageNumber,
          data: getRoomSelectionData(),
        }),
    },
  ],
});

const bookNowButtonSelector = ".book_now";

const getRoomSelectionData = (requireVisibility = false) => ({
  ...getDateSearchData(),
  rooms: getRooms(requireVisibility),
});

const isRoomSelection = () => {
  try {
    getRoomSelectionData(true);
  } catch {
    return false;
  }

  const wrapper = tryGetElementBySelector(".choose_room");
  return !!wrapper && !wrapper.classList.contains("hide");
};

const getRooms = (requireVisibility: boolean): Room[] =>
  getElementsBySelector("[data-room-type-id]", {
    requireVisibility,
  })
    .map((e) => getRoom(e, requireVisibility))
    .filter((r) => r.quantity > 0);

const getRoom = (room: Element, requireVisibility: boolean): Room => ({
  type:
    getElementBySelector(".av-name", {
      parent: room,
      requireVisibility,
    }).textContent ?? "",
  rate:
    getElementBySelector(".md-radio input[type=radio]:checked ~ label em", {
      parent: room,
      requireVisibility,
    }).textContent ?? "",
  adults: Number(getSelectDropdownValue(".av-adults", room, requireVisibility)),
  children: Number(
    getSelectDropdownValue(".av-children", room, requireVisibility)
  ),
  quantity: Number(
    getSelectDropdownValue(".av-quantity", room, requireVisibility)
  ),
});

const getSelectDropdownValue = (
  selector: string,
  parent: Element,
  requireVisibility: boolean
): string =>
  getElementBySelector(".dropdown-toggle", {
    parent: getElementBySelector(selector, { parent, requireVisibility }),
    requireVisibility,
  }).title;
