import {
  Stage,
  StageName,
  VersionIgnorantFunnelEvent,
  getElementBySelector,
  tryGetElementBySelector,
} from "../shared";
import { getFunnelEvents } from "./shared";

const stage: StageName = "guest_details";

export const getGuestDetails = (
  recordEvent: (event: VersionIgnorantFunnelEvent) => void,
  stageNumber: number
): Stage => ({
  active: isGuestDetails,
  trackedEvents: [
    ...getFunnelEvents(
      (event) => recordEvent({ ...event, stage, stageNumber }),
      getGuestDetailsData
    ),
  ],
});

const isGuestDetails = () =>
  !!tryGetElementBySelector("[data-testid=guest-page]");

const getGuestDetailsData = () => ({
  email: getElementBySelector<HTMLInputElement>(
    "[data-testid=guest-form-email-input]"
  ).value,
});
