import {
  Stage,
  StageName,
  VersionIgnorantFunnelEvent,
  getElementBySelector,
  getVisibilityChangeEvents,
  onFunnelEntry,
  toDateString,
  tryGetElementBySelector,
} from "../shared";
import { getFunnelEntryData } from "./shared";

const stage: StageName = "date_selection";

export const getDateSelection = (
  recordEvent: (event: VersionIgnorantFunnelEvent) => void,
  stageNumber: number
): Stage => ({
  active: isDateSelection,
  onLoad: () =>
    onFunnelEntry(() =>
      recordEvent({
        event: "start",
        stage,
        stageNumber,
        data: getFunnelEntryData(),
      })
    ),
  trackedEvents: [
    ...getVisibilityChangeEvents((event) =>
      recordEvent({
        event,
        stage,
        stageNumber,
        data: getDateSearchData(),
      })
    ),
    {
      type: "element",
      event: "click",
      selector: datepickerSearchButtonSelector,
      listener: () =>
        recordEvent({
          event: "click.continue",
          stage,
          stageNumber,
          data: getDateSearchData(),
        }),
    },
  ],
});

const getDateSearchData = () => ({
  startDate: toDateString(
    getElementBySelector(
      "[data-testid=landing-search-panel-date-picker-checkin-input]"
    ).textContent
  ),
  endDate: toDateString(
    getElementBySelector(
      "[data-testid=landing-search-panel-date-picker-checkout-input]"
    ).textContent
  ),
});

const datepickerSearchButtonSelector =
  "[data-testid=landing-search-panel-search-button]";

const isDateSelection = () => {
  try {
    // TODO - do we need this for v3?
    getDateSearchData();
  } catch {
    return false;
  }

  return !!tryGetElementBySelector("[data-testid=landing-page]");
};
