import {
  Stage,
  StageName,
  VersionIgnorantFunnelEvent,
  tryGetElementBySelector,
} from "../shared";
import { getFunnelEvents } from "./shared";

const stage: StageName = "payment";

export const getPayment = (
  recordEvent: (event: VersionIgnorantFunnelEvent) => void,
  stageNumber: number
): Stage => ({
  active: isPayment,
  trackedEvents: [
    ...getFunnelEvents((event) =>
      recordEvent({ ...event, stage, stageNumber })
    ),
  ],
});

const isPayment = () => !!tryGetElementBySelector("[data-testid=payment-page]");
