import { v4 } from "uuid";

const getUserId = (): string => {
  const userIdKey = "tracker-user-id";
  let userIdValue = localStorage.getItem(userIdKey);

  if (userIdValue == null) {
    userIdValue = v4();
    localStorage.setItem(userIdKey, userIdValue);
  }

  return userIdValue;
};

const getSessionId = (): string => {
  const sessionIdKey = "tracker-session-id";

  let sessionIdValue = sessionStorage.getItem(sessionIdKey);

  if (sessionIdValue == null) {
    sessionIdValue = v4();
    sessionStorage.setItem(sessionIdKey, sessionIdValue);
  }

  return sessionIdValue;
};

export const getPropertyId = (): string => {
  const pathAsArray = window.location.pathname.split("/");

  const reservationString = pathAsArray.includes("reservation")
    ? "reservation"
    : pathAsArray.includes("reservas")
    ? "reservas"
    : null;

  if (reservationString == null) {
    throw Error("Url should contain reservation string");
  }

  // Item after /reservation/ or /reservas/ is the property ID
  const reservationIndex = pathAsArray.indexOf(reservationString);
  const propertyCode: string | undefined = pathAsArray[reservationIndex + 1];

  if (!propertyCode) throw Error("Url should contain property ID");

  return propertyCode;
};

interface SharedEventData {
  userId: string;
  sessionId: string;
  userAgent: string;
  propertyId: string;
}

interface GetSharedEventDataOptions {
  getPropertyIdOverride?: () => string;
}

export const getSharedEventData = ({
  getPropertyIdOverride,
}: GetSharedEventDataOptions = {}): SharedEventData => ({
  userId: getUserId(),
  sessionId: getSessionId(),
  userAgent: window.navigator.userAgent,
  propertyId: getPropertyIdOverride?.() ?? getPropertyId(),
});
