import { DocumentEvent } from "./stage";

export const getVisibilityChangeEvents = (
  listener: (event: "resume" | "pause") => void
): DocumentEvent[] => [
  {
    type: "document",
    event: "visibilitychange",
    listener: () =>
      listener(document.visibilityState == "visible" ? "resume" : "pause"),
  },
];
