import { ElementEvent } from "../../shared";
import { tryGetDateQueryParameter } from "../../shared/urlHelpers";

const datepickerCheckoutInputSelector =
  "[data-testid=header-search-panel-date-picker-checkout-input]";

export const getDateSearchData = () => ({
  startDate: tryGetDateQueryParameter("checkin"),
  endDate: tryGetDateQueryParameter("checkout"),
});

export const getDateSearchEvents = (
  listener: (data: object) => void
): ElementEvent[] => [
  {
    type: "element",
    event: "change",
    selector: datepickerCheckoutInputSelector,
    listener: () => listener(getDateSearchData()),
  },
];
