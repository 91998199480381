const propertyIdKey = "property-id";

export const getPropertyIdForSession = (): string => {
  const propertyIdValue = sessionStorage.getItem(propertyIdKey);

  if (propertyIdValue == null) {
    throw Error("Unable to find property key for session");
  }

  return propertyIdValue;
};

export const setPropertyIdForSession = (propertyIdValue: string): void => {
  sessionStorage.setItem(propertyIdKey, propertyIdValue);
};
