import { EventName, getVisibilityChangeEvents } from "../../shared";
import { getDateSearchEvents } from "./dateSearch";

export const getFunnelEvents = (
  recordEvent: ({
    event,
    data,
  }: {
    event: EventName;
    data: object | null;
  }) => void,
  getStageData?: () => object
) => [
  ...getVisibilityChangeEvents((event) =>
    recordEvent({
      event,
      data: getStageData?.() ?? null,
    })
  ),
  ...getDateSearchEvents((data) =>
    recordEvent({
      event: "click.search_dates",
      data,
    })
  ),
];
